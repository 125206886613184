const { slugify } = require('../../../src/common/utils/slugify')

const Areas = [
  { area: '' },
  {
    area: 'LA PALMA',
    provincia: 'LA PALMA',
    slug: 'la-palma',
    lat: '28.6861',
    lon: '-17.7614',
  },
  {
    area: 'TENERIFE',
    provincia: 'TENERIFE',
    slug: 'tenerife',
    lat: '28.4722',
    lon: '-16.2944',
  },
  {
    area: 'GRAN CANARIA',
    provincia: 'GRAN CANARIA',
    slug: 'gran-canaria',
    lat: '27.95',
    lon: '-15.3667',
  },
  {
    area: 'FUERTEVENTURA',
    provincia: 'FUERTEVENTURA',
    slug: 'fuerteventura',
    lat: '28.4167',
    lon: '-14.0167',
  },
]

function inArea(taller, area) {
  // Es el "area" global (todos los talleres)
  if (!area.provincia) {
    return true
  }

  if (area.provincia !== taller.provincia) {
    return false
  }

  // Es un area sin división por poblacion
  if (!area.poblacion) {
    return true
  }

  if ('include' in area.poblacion) {
    return taller.poblacion.trim().startsWith(area.poblacion.include)
  } else {
    return !taller.poblacion.trim().startsWith(area.poblacion.exclude)
  }
}

function whichArea(taller) {
  return Areas.filter(a => !!a.area).find(a => inArea(taller, a))
}

function displayName(area) {
  return `areas.${slugify(area.area)}`
}

function getCoordinates(area) {
  const { lat, lon } = Areas.find(a => a.area === area)
  return { lat, lon }
}

module.exports = { Areas, inArea, whichArea, displayName, getCoordinates }
